<template>
  <div id="netvs_hub">
    <h1>{{ $t('system.netvs_hub')}}</h1>
    <p>{{ $t('views.hub.description') }}</p>
    <p>{{ $t('views.hub.repository_and_documentation') }}: <a href="https://gitlab.kit.edu/scc-net/netvs/netvs-hub">https://gitlab.kit.edu/scc-net/netvs/netvs-hub</a></p>
    <Loading :data="[templates]">
      <div v-if="templates !== null && templates.length === 0" class="text-muted fill text-center">{{ $t('views.hub.no_workflows') }}</div>
      <b-row>
        <b-col lg="4" md="6" v-for="t in templates" :key="t.file_name">
          <b-card :title="t.name[$store.state.locale] || t.name['de']" :sub-title="t.author" style="height: 100%;">
            <b-card-text>
              {{ t.description[$store.state.locale] }}
            </b-card-text>
            <b-button @click="loadTemplate(t.file_name)" variant="primary">{{ $t('system.execute') }}</b-button>
          </b-card>
        </b-col>
      </b-row>
      <JSONTemplateTransactionGroupEditor :template="current_json_template" :bound_variables="{}"
                                          modal_id="hub_json_template_editor" :old_uuid="null"/>
    </Loading>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import HubService from '@/api-services/hub.service'
import JSONTemplateTransactionGroupEditor from '@/components/db-editor/JSONTemplateTransactionGroupEditor.vue'
import apiutil from '@/util/apiutil'

export default {
  name: 'NETVSHub',
  components: { JSONTemplateTransactionGroupEditor, Loading },
  data() {
    return {
      current_json_template: null,
      templates: null
    }
  },
  methods: {
    async fetch() {
      const res = await HubService.getIndex()
      const tpl = []
      for (const t of res.data) {
        let has_perm = true
        if ('required_permissions' in t) {
          for (const perm of t.required_permissions) {
            if (!apiutil.checkPermission(this.$store.state, perm)) {
              has_perm = false
              break
            }
          }
        }
        if (has_perm) {
          tpl.push(t)
        }
      }
      this.templates = tpl
    },
    async loadTemplate(file_name) {
      const res = await HubService.getTemplate(file_name)
      this.current_json_template = res.data
      this.$bvModal.show('hub_json_template_editor')
    }
  },
  async mounted() {
    await this.fetch()
  }
}
</script>

<style scoped>

</style>
